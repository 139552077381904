import React, { CSSProperties, MouseEventHandler, ReactNode } from 'react';

export interface ICommonComponentProps {
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  onClick?: MouseEventHandler;
}

export type TCommonMouseRelaseEvent = 'mousedown' | 'mouseup';

export type TInDirection = 0 | 1;

export type Building = {
  id: string;
  name?: string;
};

export enum EMouseButton {
  LEFT = 0, // Main button pressed, usually the left button or the un-initialized state
  MIDDLE = 1, // Auxiliary button pressed, usually the wheel button or the middle button (if present)
  RIGHT = 2, // Secondary button pressed, usually the right button
  BACK = 3, // Fourth button, typically the Browser Back button
  FORWARD = 4, // Fifth button, typically the Browser Forward button
}

export enum EQuestionTooltipKey {
  DAILY_ENTRIES = 'daily_entries',
  DAILY_EXITS = 'daily_exits',
  OCCUPANCY_TREND = 'occupancy_trend',
  EST_OCCUPANCY = 'est_occupancy',
  EST_OCCUPANCY_TREND = 'est_occupancy_trend',
  EST_OCCUPANCY_CHART = 'est_occupancy_chart',
  SPACE_VISUALIZATIONS_TOGGLE = 'space_visualizations_toggle',
}

export enum ECommonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum ESize {
  xs = 'xs',
  sm = 'sm',
  base = 'base',
  lg = 'lg',
  xl = 'xl',
}

export enum EDateRange {
  LAST_24H = 'last24h',
  LAST_2D = 'last2d',
  LAST_7D = 'last7d',
  LAST_30D = 'last30d',
}

export enum EChartMode {
  TRAFFIC = 'traffic',
  OCCUPANCY = 'occupancy',
}

export enum EDataSource {
  TRAFFIC = 'Traffic data',
  PRESENCE = 'Presence data',
  NONE = 'No Data Found',
}

export enum ETrafficMode {
  INDIVIDUAL = 'individual',
  COMBINED = 'combined',
}

export enum EOccupancyMode {
  LIVE = 'live',
  EST = 'est',
}

export enum EQueryParam {
  SID = 'spaceId',
  RID = 'roomId',
  ZID = 'zoneId',
  BID = 'buildingId',
}

export enum ESQUnit {
  METRIC = 'sqm',
  INCH = 'sqin',
  FEET = 'sqft',
}

export enum EAmPm {
  AM = 'am',
  PM = 'pm',
}

export type TError = {
  message: string;
};

export type TDimensionType = {
  width: number;
  height: number;
  margin: { top: number; right: number; left: number; bottom: number };
};

export type TScreenPos = {
  x: number;
  y: number;
};

export type TXYPoint = [number, number];
export type TRectangle = [number, number, number, number];

export type TRectCoords = [TXYPoint, TXYPoint, TXYPoint, TXYPoint];
export type TBounds = {
  bottomLeft: TXYPoint;
  bottomRight: TXYPoint;
  topRight: TXYPoint;
  topLeft: TXYPoint;
};
export type TXYZPoint = [number, number, number];

export interface IFailurePayload {
  error: TError;
}
export interface IQuestionTooltipContent {
  beta?: boolean;
  title?: string;
  description: ReactNode;
}

export interface IQuestionTooltip {
  [key: string]: IQuestionTooltipContent[];
}

export interface IAxisDateLabel {
  date?: string;
  time?: string;
}

// Below interface is used for adding callbacks to rtk query/mutation
// e.g. Loading detections needs to pass its progress. Instead of global store, it'd be good to pass through callback
export interface IRTKCallbackParams {
  onStart?: () => void;
  onProgress?: (progress: number) => void;
  onEnd?: () => void;
}
