import { FC, memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { gql } from '@apollo/client';
import { CountryCode, getCountry, getTimezone } from 'countries-and-timezones';
import { useSRZId } from '@/hooks';
import {
  Input,
  LoadingSpinner,
  LabelValuePair,
  PersonIcon,
  Dropdown,
  CountryDropdownContent,
  SearchDropdown,
  CopyButton,
} from '@/components';
import { ESQUnit, IRoom, IZone } from '@/types';
import { AutoTooltip } from '@/components/primitives/AutoTooltip';
import { useTranslation } from 'react-i18next';
import { FloorDetails, useGetFloorQuery, useSaveFloor } from '@/components/DetailsPanel/getFloor';
import clsx from 'clsx';
import { DeleteSpaceButton } from './DeleteSpaceButton';
import { Button } from '@/components/primitives';
import { MeasureUnitSelect } from '@/components/DetailsPanel/MeasureUnitSelect';
import { DropdownTriggerIcon } from '@/components/primitives/Dropdown/Dropdown';
import { useFeatureGate } from '@statsig/react-bindings';
import {
  DetailsPanelBuildingFragment,
  useDetailsPanelRoomQuery,
  useDetailsPanelZoneQuery,
  useUpdateRoomDetailMutation,
  useUpdateZoneDetailMutation,
  useGetAllBuildingsQuery,
  useMoveFloorMutation,
} from '@/.generated/graphql';
import { datadogRum } from '@datadog/browser-rum';
import DateInput from '@/components/primitives/DateInput';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_ISO8601_FORMAT, ZERO_TIME, isDev } from '@/constants';
import { SidebarMenuSiteQuery } from '../sidebar/SidebarMenu';

interface IDetailsPanelProps {
  isRoom: boolean;
  isZone: boolean;
  isStudio?: boolean | undefined;
}

interface ISpaceDetailsProps {
  space: FloorDetails;
  isStudio?: boolean;
  toggleIsEditorMode: () => void;
}

interface IRoomDetailsProps {
  floor: FloorDetails;
  room: IRoom;
  toggleIsEditorMode: () => void;
}

interface IZoneDetailsProps {
  space: FloorDetails;
  zone: IZone;
  toggleIsEditorMode: () => void;
}

const SpaceDetailsDisplay: FC<ISpaceDetailsProps> = memo(({ space, toggleIsEditorMode }) => {
  const { t } = useTranslation();
  const building = space.building;

  const lastBatteryChangeDates = (space?.sensors ?? [])
    .map((s) => s.last_battery_change_date)
    .filter((s) => s)
    .toSorted();
  const nextBatteryChangeDates = (space?.sensors ?? [])
    .map((s) => s.next_battery_change_date)
    .filter((s) => s)
    .toSorted();
  const lastBatteryChangeDate =
    lastBatteryChangeDates.length > 0
      ? lastBatteryChangeDates[0] === lastBatteryChangeDates[lastBatteryChangeDates.length - 1]
        ? lastBatteryChangeDates[0]
        : 'Mixed'
      : 'No date selected';
  const nextBatteryChangeDate =
    nextBatteryChangeDates.length > 0
      ? nextBatteryChangeDates[0] === nextBatteryChangeDates[nextBatteryChangeDates.length - 1]
        ? nextBatteryChangeDates[0]
        : 'Mixed'
      : 'No date selected';
  const oldestBatteryChangeDate = lastBatteryChangeDates.length > 0 && lastBatteryChangeDates[0];
  const newestBatteryChangeDate =
    lastBatteryChangeDates.length > 0 && lastBatteryChangeDates[lastBatteryChangeDates.length - 1];

  const { value: battertDateEnabled } = useFeatureGate('sensor_battery_change_date');

  return (
    <>
      <div className="pt-8 pb-5">
        <div className="px-5 mb-5 text-lg font-semibold text-gray-900">{t('spaceInformation')}</div>
        <LabelValuePair label={t('name')} value={space.name} className="text-sm" />
        <LabelValuePair label={t('customId')} value={space.customID} className="text-sm" />
        <LabelValuePair
          label={t('id')}
          value={
            <>
              <span className="inline-block max-w-[150px] truncate">{space.id}</span>
              <CopyButton
                className="h-6 w-6 cursor-pointer rounded bg-product-gray100 px-[5px] text-black hover:bg-product-gray200 hover:text-black"
                text={space.id}
              />
            </>
          }
          className="text-sm"
        />
        <LabelValuePair label={t('timezone')} value={space.timezone} className="text-sm" />
        <LabelValuePair
          label={t('size')}
          value={Math.round(space.area.value)}
          className="text-sm"
        />
        {/* need to hide this for now: https://app.asana.com/0/1203155419382718/1206407483147892/f  */}
        {/* {isStudio ? (
            <LabelValuePair
              label={t('installationStatus')}
              value={t(getInstallationStatusTranslation(space.installation_status))}
            />
          ) : null} */}
        <LabelValuePair
          label={t('unit')}
          value={
            <span className="ml-1 text-base pr-1">
              {space.area.unit === ESQUnit.METRIC ? 'm' : 'ft'}
              <span className="absolute -translate-y-1/4 text-xxxs">2</span>
            </span>
          }
          className="text-sm"
        />
        <LabelValuePair
          label={t('capacityLimit')}
          value={
            <div className="flex flex-row gap-1 text-right text-sm text-gray-500">
              {space.capacity.max} <PersonIcon />
            </div>
          }
          className="text-sm"
        />
        <LabelValuePair
          label={t('capacityWarning')}
          value={
            <div className="flex flex-row gap-1 text-right text-sm text-gray-500">
              {space.capacity.mid} <PersonIcon />
            </div>
          }
          className="text-sm"
        />
        <LabelValuePair
          label={t('address')}
          value={
            <div>
              <div className="text-right">
                {building.address?.lines
                  .filter((s) => s && s.length > 0)
                  .map((line) => <p key={line}>{line}</p>)}
              </div>
              <div className="text-right">{building.address?.country}</div>
            </div>
          }
          className="text-sm"
        />
        {(battertDateEnabled || isDev) && (
          <LabelValuePair
            label={t('batteryManagement')}
            value={
              <>
                <div className="p-3 rounded bg-gray-50 flex flex-col gap-4 mb-2 text-xs leading-5">
                  <div className="flex justify-between">
                    <span className="text-gray-700 font-semibold">{t('oldestBattery')}</span>
                    <span>
                      {' '}
                      {oldestBatteryChangeDate
                        ? dayjs(oldestBatteryChangeDate, DATE_ISO8601_FORMAT).format(DATE_FORMAT)
                        : 'N/A'}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-700 font-semibold">{t('newestBattery')}</span>
                    <span>
                      {' '}
                      {newestBatteryChangeDate
                        ? dayjs(newestBatteryChangeDate, DATE_ISO8601_FORMAT).format(DATE_FORMAT)
                        : 'N/A'}
                    </span>
                  </div>
                </div>
                <div className="p-3 rounded bg-gray-50 flex flex-col gap-4 mb-4 text-xs leading-5">
                  <div className="flex flex-col gap-1">
                    <p className="text-gray-700 font-semibold">Last Change Date</p>
                    <p>
                      {' '}
                      {lastBatteryChangeDate !== 'No date selected'
                        ? lastBatteryChangeDate === 'Mixed'
                          ? lastBatteryChangeDate
                          : dayjs(lastBatteryChangeDate, DATE_ISO8601_FORMAT).format(DATE_FORMAT)
                        : lastBatteryChangeDate}
                    </p>
                  </div>
                  <div className="flex flex-col gap-1">
                    <AutoTooltip
                      label={
                        <div className="w-80 leading-5">
                          Next Change Date is manually set and may change due to system enhancements
                          that improve battery life or changes to the device configuration.
                        </div>
                      }
                      placement="left-start"
                      zIndex={20}
                      variant="black"
                    >
                      <p className="text-gray-700 font-semibold cursor-help">Next Change Date</p>
                    </AutoTooltip>
                    <p>
                      {' '}
                      {nextBatteryChangeDate !== 'No date selected'
                        ? nextBatteryChangeDate === 'Mixed'
                          ? nextBatteryChangeDate
                          : dayjs(nextBatteryChangeDate, DATE_ISO8601_FORMAT).format(DATE_FORMAT)
                        : nextBatteryChangeDate}
                    </p>
                  </div>
                </div>
              </>
            }
            multiline
          />
        )}
        {building && (
          <LabelValuePair
            label={t('parentBuilding')}
            value={
              <div className="p-4 rounded bg-gray-50 flex flex-col gap-2">
                <p className="text-sm text-gray-700 font-semibold">{t('buildingName')}</p>
                <p className="text-sm text-gray-500">{building.name}</p>
              </div>
            }
            multiline
          />
        )}
        <div className="flex flex-col gap-2 mt-6 px-5">
          <Button
            data-id="editDetailsButton"
            variant="solid"
            onClick={() => {
              datadogRum.addAction('Click Edit Floor', {
                source: 'edit-details',
              });
              toggleIsEditorMode();
            }}
            className="py-2 font-semibold leading-[22px]"
          >
            {t('editSettings')}
          </Button>
          <DeleteSpaceButton data-id="delete-floor" />
        </div>
      </div>
    </>
  );
});
const SpaceDetailsEditor: FC<ISpaceDetailsProps> = memo(
  ({ space: floorData, toggleIsEditorMode }) => {
    const { t } = useTranslation();
    const [save] = useSaveFloor();
    const [moveFloor] = useMoveFloorMutation({
      refetchQueries: [{ query: SidebarMenuSiteQuery }],
    });
    const { data: buildingData } = useGetAllBuildingsQuery();
    const { register, setValue, handleSubmit, watch } = useForm({
      defaultValues: {
        ...floorData,
        parentBuilding: floorData.building,
      },
    });

    const parentBuilding = watch('parentBuilding');
    const selectedBuilding = buildingData?.buildings.data.find((s) => s.id === parentBuilding.id);
    const { value: battertDateEnabled } = useFeatureGate('sensor_battery_change_date');
    const onSubmit = async ({
      building,
      ...floor
    }: FloorDetails & { building: DetailsPanelBuildingFragment }) => {
      datadogRum.addAction('Save Floor Details', { source: 'edit-details' });
      if (watchBuilding.id !== floorData.building.id) {
        datadogRum.addAction('Move floor to new building', {
          source: 'sites-and-buildings',
        });
        await moveFloor({
          variables: { input: { buildingId: watchBuilding.id, floorId: floor.id } },
        });
      }
      await save({
        variables: {
          input: {
            id: floor.id,
            name: floor.name,
            installationStatus: floor.installation_status,
            capacity: floor.capacity
              ? {
                  capacity: floor.capacity?.max,
                  mid_capacity: floor.capacity?.mid,
                }
              : undefined,
            area: {
              area: floor.area.value,
              area_unit: floor.area.unit,
            },
            customId: floor.customID,
            sensorLastBatteryChangedAt: floor.last_battery_change_date,
            sensorNextBatteryChangeAt: floor.next_battery_change_date,
          },
          building: {
            id: building.id,
            address: building.address
              ? {
                  lines: building.address.lines ?? undefined,
                  country: building.address?.country ?? 'USA',
                }
              : null,
          },
        },
      });
      toggleIsEditorMode();
    };

    const watchUnit = watch('area.unit') as ESQUnit;
    const watchBuilding = watch('parentBuilding');
    const watchAddress = watch('building.address');
    const watchLastBatteryChangeDate = watch('last_battery_change_date');
    const watchNextBatteryChangeDate = watch('next_battery_change_date');

    const building = floorData.building;
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-60 px-5 pt-8">
          <div className="mb-5 text-lg font-semibold text-gray-900">
            {t('editSpaceInformation')}
          </div>
          <Input type="text" label={t('spaceName')} wrapperClassName="mb-4" {...register('name')} />
          <Input
            type="text"
            {...register('customID')}
            label={t('customId')}
            wrapperClassName="mb-4"
          />
          <div className="py-3">
            <LabelValuePair
              label={t('id')}
              value={
                <>
                  <span className="inline-block max-w-[150px] truncate">{floorData.id}</span>
                  <CopyButton
                    className="h-6 w-6 cursor-pointer rounded bg-product-gray100 px-[5px] text-black hover:bg-product-gray200 hover:text-black"
                    text={floorData.id}
                  />
                </>
              }
              className="text-sm px-0"
            />
          </div>
          <AutoTooltip
            label="Site timezones are configured during a Butlr system installation. If this timezone does not match the physical location of this site, please reach out to Butlr Support"
            maxWidth={250}
          >
            <Input
              type="text"
              label={t('timezone')}
              value={floorData.timezone ? getTimezone(floorData.timezone)?.name : t('notSet')}
              wrapperClassName="mb-4"
              disabled
            />
          </AutoTooltip>
          {/* need to hide this for now: https://app.asana.com/0/1203155419382718/1206407483147892/f  */}
          {/* {isStudio ? (
            <>
              <div className="text-sm text-product-gray600">{t('installationStatus')}</div>
              <InstallationStatusSelect
                value={watchInstallStatus}
                onChange={(value) => setValue('data.installation_status', value)}
                className="mb-2"
              />
            </>
          ) : null} */}
          <Input
            type="number"
            label={t('area')}
            trailingContent={
              <span className="whitespace-nowrap">
                {floorData.area.unit === ESQUnit.METRIC ? t('sqMeters') : t('sqFeet')}
              </span>
            }
            wrapperClassName="mb-4"
            {...register('area.value')}
          />
          <div className="text-sm text-product-gray600">{t('unit')}</div>
          <MeasureUnitSelect
            value={watchUnit}
            onChange={(value) => setValue('area.unit', value)}
            className="mb-4"
          />
          <div className="mb-4 flex">
            <div className="w-1/2 pr-2">
              <Input type="number" label={t('capacityLimit')} {...register('capacity.max')} />
            </div>
            <div className="w-1/2 pl-2">
              <Input type="number" {...register('capacity.mid')} label={t('capacityWarning')} />
            </div>
          </div>
          <div>
            <Input
              type="text"
              {...register('building.address.lines.0')}
              label={t('address')}
              placeholder={t('address')}
              wrapperClassName="mb-2"
            />
            <Input
              type="text"
              {...register('building.address.lines.1')}
              placeholder={t('suiteRoom')}
              wrapperClassName="mb-2"
            />
            <Input
              type="text"
              {...register('building.address.lines.2')}
              placeholder={`${t('city')} ${t('state')} ${t('zipcode')}`}
              wrapperClassName="mb-2"
            />
            <Dropdown
              wrapperClassName="mb-2 w-full"
              buttonClassName="bg-product-gray100 w-full px-4 py-4"
              itemsClassName="right-2 z-30"
              // prevents shifting around during search as the content gets shorter
              side="top"
              dropdownButtonContent={
                <div className="flex w-full justify-between">
                  <span>
                    {(watchAddress?.country && getCountry(watchAddress?.country)?.name) ??
                      t('country')}
                  </span>
                  <DropdownTriggerIcon />
                </div>
              }
              dropdownContent={
                <CountryDropdownContent
                  code={watchAddress?.country as CountryCode}
                  onSelect={({ id }) => setValue('building.address.country', id)}
                />
              }
            />
          </div>
          {(battertDateEnabled || isDev) && (
            <div className="py-3 rounded border-[1px] border-gray-200 flex flex-col gap-4 mb-4 text-xs leading-5">
              <LabelValuePair
                multiline
                label="Last Change Date"
                value={
                  <DateInput
                    value={
                      watchLastBatteryChangeDate === ZERO_TIME ? '' : watchLastBatteryChangeDate
                    }
                    onChange={(date) =>
                      setValue(
                        'last_battery_change_date',
                        date ? dayjs(date, DATE_FORMAT).format(DATE_ISO8601_FORMAT) : '',
                      )
                    }
                  />
                }
                style={{
                  paddingLeft: '16px',
                }}
              />
              <LabelValuePair
                multiline
                label="Next Change Date"
                value={
                  <AutoTooltip label="Next Change Date is manually set and may change due to system enhancements that improve battery life or changes to the device configuration.">
                    <DateInput
                      value={
                        watchNextBatteryChangeDate === ZERO_TIME ? '' : watchNextBatteryChangeDate
                      }
                      onChange={(date) =>
                        setValue(
                          'next_battery_change_date',
                          date ? dayjs(date, DATE_FORMAT).format(DATE_ISO8601_FORMAT) : '',
                        )
                      }
                    />
                  </AutoTooltip>
                }
                style={{
                  paddingLeft: '16px',
                }}
              />
            </div>
          )}
          {building && buildingData && (
            <>
              <span className="font-semibold text-xs text-product-gray600">
                {t('parentBuilding')}
              </span>
              <div className="border rounded p-3">
                <span className="font-semibold text-xs text-product-gray600">
                  {t('buildingName')}
                </span>
                <SearchDropdown
                  id="serialNumber"
                  size="sm"
                  options={buildingData?.buildings?.data}
                  optionToSearchValue={(option) => (option ? option.name : '')}
                  renderOption={(option) => option.name}
                  placeholder="Building Name"
                  className="w-full text-gray-900 my-3"
                  onChange={(value) => {
                    datadogRum.addAction('Select Parent Building', {
                      source: 'edit-details',
                    });
                    if (value) {
                      const selectedBuilding = buildingData?.buildings?.data.find(
                        (building) => building.id === value.id,
                      );
                      if (selectedBuilding) {
                        setValue('parentBuilding', selectedBuilding, { shouldDirty: true });
                      }
                    }
                  }}
                  value={selectedBuilding}
                />
                <span className="text-xs text-product-gray600 block mb-3">{t('moveFloor')}</span>
                <span className="font-semibold text-xs text-product-gray600 mb-3">
                  {t('customId')}
                </span>
                <Input
                  type="text"
                  className="bg-transparent"
                  placeholder={'Building ID'}
                  value={building?.buildingNumber?.toString()}
                  disabled
                />
              </div>
            </>
          )}
        </div>

        <div className="fixed right-0 bottom-0 z-20 w-80 border-t border-l border-gray-200 bg-white">
          <div className="flex justify-center py-4 align-middle">
            <Button
              data-id="detailsCancelButton"
              variant="ghost"
              onClick={() => toggleIsEditorMode()}
            >
              {t('cancel')}
            </Button>
            <Button data-id="detailsSaveButton" variant="solid" type="submit">
              {t('save')}
            </Button>
          </div>
        </div>
      </form>
    );
  },
);

const RoomDetailsDisplay: FC<IRoomDetailsProps> = memo(({ floor, room, toggleIsEditorMode }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="pt-8 pb-5">
        <div className="px-5 mb-5 text-lg font-semibold text-gray-900">Room Information</div>
        <LabelValuePair label={t('roomName')} value={room.name} className="text-sm" />
        <LabelValuePair label={t('type')} value={room.type} className="text-sm" />
        <LabelValuePair
          label={t('area')}
          value={
            <>
              <span className="text-sm leading-6">{Math.round(room.area.value)}</span>
              <span className="ml-1 text-base pr-1">
                {floor.area.unit === ESQUnit.METRIC ? 'm' : 'ft'}
                <span className="absolute -translate-y-1/4 text-xxxs">2</span>
              </span>
            </>
          }
          className="text-sm"
        />
        <LabelValuePair
          label={t('capacityLimit')}
          value={
            <div className="flex flex-row gap-1 text-right text-sm text-gray-500">
              {room.capacity.max} <PersonIcon />
            </div>
          }
          className="text-sm"
        />
        <LabelValuePair
          label={t('capacityWarning')}
          value={
            <div className="flex flex-row gap-1 text-right text-sm text-gray-500">
              {room.capacity.mid} <PersonIcon />
            </div>
          }
          className="text-sm"
        />
        <LabelValuePair label={t('customId')} value={room.customID} className="text-sm" />
        <LabelValuePair
          label={t('id')}
          value={
            <>
              <span className="inline-block max-w-[150px] truncate">{room.id}</span>
              <CopyButton
                className="h-6 w-6 cursor-pointer rounded bg-product-gray100 px-[5px] text-black hover:bg-product-gray200 hover:text-black"
                text={room.id}
              />
            </>
          }
          className="text-sm"
        />
        <LabelValuePair
          label={t('parentFloor')}
          value={
            <div className="p-4 rounded bg-gray-50 flex flex-col gap-2">
              <p className="text-sm text-gray-700 font-semibold">{t('floorName')}</p>
              <p className="text-sm text-gray-500">{floor.name}</p>
            </div>
          }
          multiline
        />
        <div className="flex flex-col gap-2 mt-6 px-5">
          <Button
            data-id="editDetailsButton"
            variant="solid"
            onClick={() => {
              datadogRum.addAction('Click Edit Room', {
                source: 'edit-details',
              });
              toggleIsEditorMode();
            }}
            className="py-2 font-semibold leading-[22px]"
          >
            <span className="product-gray900 text-sm">{t('editSettings')}</span>
          </Button>
        </div>
      </div>
    </>
  );
});

const RoomDetailsEditor = ({ floor: space, room, toggleIsEditorMode }: IRoomDetailsProps) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      data: room,
    },
  });
  const [updateRoomDetail] = useUpdateRoomDetailMutation({
    refetchQueries: ['DetailsPanelRoom', 'useSidebarSiteQuery'],
  });
  // Listen when save button is clicked
  const handleSave = ({ data: roomToUpdate }: { data: IRoom }) => {
    datadogRum.addAction('Save Room Details', { source: 'edit-details' });
    updateRoomDetail({
      variables: {
        rooms: [
          {
            room_id: roomToUpdate.id,
            name: roomToUpdate.name,
            capacity: {
              capacity: roomToUpdate.capacity.max,
              mid_capacity: roomToUpdate.capacity.mid,
            },
            area: {
              area: roomToUpdate.area.value,
            },
            customID: roomToUpdate.customID,
          },
        ],
      },
    });
    toggleIsEditorMode();
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <div className="border-b border-gray-200 px-5 pt-8 pb-5">
        <div className="mb-5 text-lg font-semibold text-gray-900">{t('editRoomInformation')}</div>
        <Input
          type="text"
          {...register('data.name')}
          label={t('roomName')}
          wrapperClassName="mb-4"
        />
        <Input
          type="number"
          {...register('data.area.value')}
          label={t('area')}
          trailingContent={space.area.unit === ESQUnit.METRIC ? t('sqMeters') : t('sqFeet')}
          wrapperClassName="mb-4"
        />
        <div className="mb-4 flex">
          <div className="w-1/2 pr-2">
            <Input type="number" {...register('data.capacity.max')} label={t('capacityLimit')} />
          </div>
          <div className="w-1/2 pl-2">
            <Input
              type="number"
              {...register('data.capacity.mid')}
              label={(<small>{t('capacityWarning')}</small>) as any}
            />
          </div>
        </div>
        <Input
          type="text"
          {...register('data.customID')}
          label={t('customId')}
          wrapperClassName="mb-4"
        />
        <div className="py-3">
          <LabelValuePair
            label={t('id')}
            value={
              <>
                <span className="inline-block max-w-[150px] truncate">{room.id}</span>
                <CopyButton
                  className="h-6 w-6 cursor-pointer rounded bg-product-gray100 px-[5px] text-black hover:bg-product-gray200 hover:text-black"
                  text={room.id}
                />
              </>
            }
            className="text-sm px-0"
          />
        </div>
      </div>

      <div className="fixed right-0 bottom-0 w-80 border-t border-l border-gray-200 bg-white">
        <div className="flex justify-evenly py-4 align-middle">
          <Button
            data-id="detailsCancelButton"
            variant="ghost"
            onClick={() => toggleIsEditorMode()}
          >
            {t('cancel')}
          </Button>
          <Button data-id="detailsSaveButton" variant="solid" type="submit">
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  );
};

const ZoneDetailsDisplay: FC<IZoneDetailsProps> = memo(({ zone, toggleIsEditorMode }) => {
  const { t } = useTranslation();
  const { data: roomData } = useDetailsPanelRoomQuery({
    variables: {
      // asserted by skip below
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      roomId: zone.room_id!,
    },
    skip: !zone.room_id,
  });
  const parentRoom = roomData?.rooms?.data?.[0];

  return (
    <>
      <div className="border-b border-gray-200 pt-8 pb-5">
        <div className="px-5 text-lg font-semibold text-gray-900">Zone Information</div>
        <LabelValuePair label={t('zoneName')} value={zone.name} className="text-sm" />
        <LabelValuePair label={t('type')} value={zone.type} className="text-sm" />
        <LabelValuePair
          label={t('area')}
          value={
            <>
              <span>{Math.round(zone.area.value)}</span>
              <span className="ml-1 text-base">
                {zone.area.unit === ESQUnit.METRIC ? 'm' : 'ft'}
                <span className="absolute -translate-y-1/4 text-xxxs">2</span>
              </span>
            </>
          }
          className="text-sm"
        />
        <LabelValuePair
          label={t('capacityLimit')}
          value={
            <div className="flex flex-row gap-1 text-right text-sm text-gray-900">
              {zone.capacity.max} <PersonIcon />
            </div>
          }
          className="text-sm"
        />
        <LabelValuePair
          label={t('capacityWarning')}
          value={
            <div className="flex flex-row gap-1 text-right text-sm text-gray-900">
              {zone.capacity.mid} <PersonIcon />
            </div>
          }
          className="text-sm"
        />
        <LabelValuePair label={t('customId')} value={zone.customID} className="text-sm" />
        <LabelValuePair
          label={t('id')}
          value={
            <>
              <span className="inline-block max-w-[150px] truncate">{zone.id}</span>
              <CopyButton
                className="h-6 w-6 cursor-pointer rounded bg-product-gray100 px-[5px] text-black hover:bg-product-gray200 hover:text-black"
                text={zone.id}
              />
            </>
          }
          className="text-sm"
        />
        {parentRoom && (
          <LabelValuePair
            label={t('parentRoom')}
            value={
              <div className="p-4 rounded bg-gray-50 flex flex-col gap-2">
                <p className="text-sm text-gray-700 font-semibold">{t('roomName')}</p>
                <p className="text-sm text-gray-500">{parentRoom?.name}</p>
              </div>
            }
            multiline
          />
        )}
        <div className="flex flex-col gap-2 mt-6 px-5">
          <Button
            data-id="editDetailsButton"
            variant="solid"
            onClick={() => {
              datadogRum.addAction('Click Edit Zone', {
                source: 'edit-details',
              });
              toggleIsEditorMode();
            }}
            className="py-2 font-semibold leading-[22px]"
          >
            {t('edit')}
          </Button>
        </div>
      </div>
      <div className="pt-5">
        <div className="px-5 text-lg font-semibold text-gray-900 h-8">{t('zoneDevices')}</div>
        <LabelValuePair label={t('headcountSensors')} value={0} className="text-sm h-8" />
        <LabelValuePair label={t('activitySensors')} value={0} className="text-sm h-8" />
      </div>
    </>
  );
});

const ZoneDetailsEditor = ({ space, zone, toggleIsEditorMode }: IZoneDetailsProps) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      data: zone,
    },
  });

  const [updateZoneDetail] = useUpdateZoneDetailMutation({
    refetchQueries: ['DetailsPanelZone', 'useSidebarSiteQuery'],
  });
  // Listen when save button is clicked
  const handleSave = ({ data: zoneToUpdate }: { data: IZone }) => {
    datadogRum.addAction('Save Zone Details', { source: 'edit-details' });
    updateZoneDetail({
      variables: {
        zones: [
          {
            zone_id: zoneToUpdate.id,
            name: zoneToUpdate.name,
            capacity: {
              capacity: zoneToUpdate.capacity.max,
              mid_capacity: zoneToUpdate.capacity.mid,
            },
            area: {
              area: zoneToUpdate.area.value,
            },
            customID: zoneToUpdate.customID,
          },
        ],
      },
    });
    toggleIsEditorMode();
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <div className="border-b border-gray-200 px-5 pt-8 pb-5">
        <div className="mb-5 text-lg font-semibold text-gray-900">{t('editZoneInformation')}</div>
        <Input
          type="text"
          {...register('data.name')}
          label={t('zoneName')}
          wrapperClassName="mb-4"
        />
        <Input
          type="number"
          {...register('data.area.value')}
          label={t('area')}
          trailingContent={space.area.unit === ESQUnit.METRIC ? t('sqMeters') : t('sqFeet')}
          wrapperClassName="mb-4"
        />
        <div className="mb-4 flex">
          <div className="w-1/2 pr-2">
            <Input type="number" {...register('data.capacity.max')} label={t('capacityLimit')} />
          </div>
          <div className="w-1/2 pl-2">
            <Input
              type="number"
              {...register('data.capacity.mid')}
              label={(<small>{t('capacityWarning')}</small>) as any}
            />
          </div>
        </div>
        <Input
          type="text"
          {...register('data.customID')}
          label={t('customId')}
          wrapperClassName="mb-4"
        />
        <div className="py-3">
          <LabelValuePair
            label={t('id')}
            value={
              <>
                <span className="inline-block max-w-[150px] truncate">{zone.id}</span>
                <CopyButton
                  className="h-6 w-6 cursor-pointer rounded bg-product-gray100 px-[5px] text-black hover:bg-product-gray200 hover:text-black"
                  text={zone.id}
                />
              </>
            }
            className="text-sm px-0"
          />
        </div>
      </div>
      <div className="fixed right-0 bottom-0 w-80 border-t border-l border-gray-200 bg-white">
        <div className="flex justify-evenly py-4 align-middle">
          <Button
            data-id="detailsCancelButton"
            variant="ghost"
            onClick={() => toggleIsEditorMode()}
          >
            {t('cancel')}
          </Button>
          <Button data-id="detailsSaveButton" variant="solid" type="submit">
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export const DetailsPanel = ({ isRoom, isZone, isStudio }: IDetailsPanelProps) => {
  const { roomId, spaceId, zoneId } = useSRZId();
  const { data: space, loading: spaceLoading } = useGetFloorQuery({ floorId: spaceId });

  const { loading: roomLoading, data: roomData } = useDetailsPanelRoomQuery({
    variables: {
      // asserted by skip below
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      roomId: roomId!,
    },
    fetchPolicy: 'cache-and-network',
    skip: !spaceId || !roomId,
  });
  const room = roomData?.rooms?.data?.[0];

  const { loading: zoneLoading, data: zoneData } = useDetailsPanelZoneQuery({
    variables: {
      // asserted by skip below
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      zoneId: zoneId!,
    },
    fetchPolicy: 'cache-and-network',
    skip: !spaceId || !zoneId,
  });
  const zone = zoneData?.zones?.data?.[0];
  const [isEditorMode, setIsEditorMode] = useState(false);
  const toggleIsEditorMode = () => setIsEditorMode(!isEditorMode);
  useEffect(() => {
    setIsEditorMode(false);
  }, [space, room, zone]);

  return (
    <div
      className={clsx(
        'z-10 h-full w-320px min-w-320px overflow-y-auto border-gray-200 bg-white rounded',
        isStudio && 'absolute right-0',
      )}
    >
      {((isZone && (zoneLoading || spaceLoading)) ||
        (isRoom && (roomLoading || spaceLoading)) ||
        (!isRoom && !zoneLoading && spaceLoading)) && <LoadingSpinner />}
      {isZone && !zoneLoading && !spaceLoading && space && zone && (
        <>
          {isEditorMode ? (
            <ZoneDetailsEditor
              space={space}
              zone={zone as IZone}
              toggleIsEditorMode={toggleIsEditorMode}
            />
          ) : (
            <ZoneDetailsDisplay
              space={space}
              zone={zone as IZone}
              toggleIsEditorMode={toggleIsEditorMode}
            />
          )}
        </>
      )}
      {isRoom && !isZone && !roomLoading && !spaceLoading && space && room && (
        <>
          {isEditorMode ? (
            <RoomDetailsEditor
              floor={space}
              room={room as IRoom}
              toggleIsEditorMode={toggleIsEditorMode}
            />
          ) : (
            <RoomDetailsDisplay
              floor={space}
              room={room as IRoom}
              toggleIsEditorMode={toggleIsEditorMode}
            />
          )}
        </>
      )}
      {!isRoom && !isZone && !spaceLoading && space && (
        <>
          {isEditorMode ? (
            <SpaceDetailsEditor
              space={space}
              isStudio={isStudio}
              toggleIsEditorMode={toggleIsEditorMode}
            />
          ) : (
            <SpaceDetailsDisplay
              space={space}
              isStudio={isStudio}
              toggleIsEditorMode={toggleIsEditorMode}
            />
          )}
        </>
      )}
    </div>
  );
};

DetailsPanel.queries = {
  MoveFloor: gql`
    mutation moveFloor($input: MoveFloorInput!) {
      moveFloor(input: $input) {
        floor {
          space_id: floor_id
          buildingId
        }
      }
    }
  `,
  Buildings: gql`
    query GetAllBuildings {
      buildings {
        data {
          id
          name
          buildingNumber
        }
      }
    }
  `,
  Room: gql`
    query DetailsPanelRoom($roomId: String!) {
      rooms(ids: [$roomId]) {
        data {
          id: room_id
          space_id: floor_id
          type: room_type
          name
          capacity {
            max
            mid
          }
          sensors {
            id: sensor_id
            mac_address
            mode
            isEntrance: is_entrance
            center
            height
            field_of_view
          }
          area {
            value
            unit
          }
          color
          coordinates
          customID
        }
      }
    }
  `,
  Zone: gql`
    query DetailsPanelZone($zoneId: String!) {
      zones(ids: [$zoneId]) {
        data {
          id: zone_id
          space_id: floor_id
          room_id
          type: zone_type
          name
          capacity {
            max
            mid
          }
          sensors {
            id: sensor_id
            mac_address
            mode
            isEntrance: is_entrance
            center
            height
            field_of_view
          }
          area {
            value
            unit
          }
          color
          coordinates
          customID
        }
      }
    }
  `,
};

RoomDetailsEditor.mutations = {
  UpdateRoomDetail: gql`
    mutation updateRoomDetail($rooms: [UpdateRoomInput!]!) {
      updateRooms(rooms: $rooms) {
        room_id
        customID
      }
    }
  `,
};
ZoneDetailsEditor.mutations = {
  UpdateZoneDetail: gql`
    mutation updateZoneDetail($zones: [UpdateZoneInput!]!) {
      updateZones(zones: $zones) {
        zone_id
        customID
      }
    }
  `,
};

export default DetailsPanel;
