import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInterval, useQuery } from '@/hooks';
import { EQueryParam } from '@/types';
import { getCurrentTimeState, updateCurrent, updateTime } from '@/redux/reducers/time';
import { LOCAL_TZ } from '@/constants';
import { useHeaderFloorsQuery, useTimezoneBuildingQuery } from '@/.generated/graphql';
import { getSingleGraphQLModel } from '@/utils/getSingleGraphQLModel';

/**
 * Hooks for fetching space/room id
 * @returns space/room id
 */
export const useSRZId = (): {
  spaceId?: string;
  roomId?: string;
  zoneId?: string;
  buildingId?: string;
} => {
  const query = useQuery();
  const spaceId = query.get(EQueryParam.SID) || undefined;
  const roomId = query.get(EQueryParam.RID) || undefined;
  const zoneId = query.get(EQueryParam.ZID) || undefined;
  const buildingId = query.get(EQueryParam.BID) || undefined;

  return { buildingId, spaceId, roomId, zoneId };
};

/**
 * Hooks for updating the initial timezone and refreshing time every 2 minutes
 */
export const useManageTime = () => {
  const dispatch = useDispatch();
  const { spaceId, buildingId } = useSRZId();
  const { data: spaceData } = useHeaderFloorsQuery({
    // skip validates this assertion.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { spaceId: spaceId! },
    skip: !spaceId,
  });
  const space = getSingleGraphQLModel(spaceData?.floors);

  const { data: buildingData } = useTimezoneBuildingQuery({
    // skip validates this assertion.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { buildingId: buildingId! },
    skip: !buildingId,
  });

  const building = buildingData?.building
    ? { id: buildingData.building.id, timezone: buildingData.building.site.timezone || '' }
    : null;

  const timezone = (space ? space.timezone : building?.timezone) || LOCAL_TZ;

  useEffect(() => {
    dispatch(updateTime(getCurrentTimeState(timezone)));
  }, [dispatch, timezone]);

  // WARNING: this is extremely fragile. messing up the stability
  // of this function will cause infinite loops of metrics requests.
  const refreshStats = useCallback(() => {
    if (!space) return;
    // Updating the time will invalidate all queries that are based on time (including stats queries)
    dispatch(
      updateCurrent({
        currentDate: new Date(),
      }),
    );
  }, [dispatch, space]);

  useInterval(120 * 1000, refreshStats, [refreshStats]);
};
