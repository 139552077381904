import { History } from 'history';

export function updateUrlParams(
  history: History<unknown>,
  params: { buildingId?: string; floorId?: string; roomId?: string; zoneId?: string },
) {
  const currentUrl = new URL(location.href);
  const searchParams = new URLSearchParams(currentUrl.search);

  // Handle floorId (spaceId)
  if (params.floorId) {
    searchParams.set('spaceId', params.floorId);
  } else {
    searchParams.delete('spaceId');
  }
  // Handle buildingId
  if (params.buildingId) {
    searchParams.set('buildingId', params.buildingId);
  } else {
    searchParams.delete('buildingId');
  }
  // Handle roomId
  if (params.roomId) {
    searchParams.set('roomId', params.roomId);
  } else {
    searchParams.delete('roomId');
  }

  // Handle zoneId
  if (params.zoneId) {
    searchParams.set('zoneId', params.zoneId);
  } else {
    searchParams.delete('zoneId');
  }

  history.push({ search: searchParams.toString() });
}
