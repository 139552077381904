import { FC, memo, useEffect } from 'react';
import { Dialog, TDialog } from '@/components';
import { useHistory } from 'react-router-dom';
import { SuperUserPage } from './SuperUserPage';
import OrganizationPage from './OrganizationPage';
import { useAppDispatch, useDialog, useSuperUser } from '@/redux/hooks';
import { closeDialog, setDialog } from '@/redux/reducers/dialog';
import { useManageTime } from '@/hooks';

export const LandingPage: FC = () => {
  useManageTime();

  const history = useHistory();
  const dialog = useDialog();
  const { isSuperUserDashboard } = useSuperUser();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isSuperUserDashboard) history.push('/');
  }, [history, isSuperUserDashboard]);

  return (
    <div className="flex h-full w-full flex-col">
      {isSuperUserDashboard ? <SuperUserPage /> : <OrganizationPage />}
      {dialog && (
        <Dialog
          dialog={dialog}
          setDialog={(dialog: TDialog | null) => dispatch(setDialog(dialog))}
          closeDialog={() => dispatch(closeDialog())}
        />
      )}
    </div>
  );
};

export default memo(LandingPage);
