import { useMemo } from 'react';
import { LOCAL_TZ } from '@/constants';
import { useSRZId } from './useOrg';
import { gql } from '@apollo/client';
import { useTimezoneSpaceQuery, useTimezoneBuildingQuery } from '@/.generated/graphql';
/**
 * Hooks for getting current timezone
 */
export const useTimezone = (timezone?: string): string => {
  const { spaceId, buildingId } = useSRZId();
  const { data } = useTimezoneSpaceQuery({
    // ! usage is asserted by skip below
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { spaceId: spaceId! },
    skip: !spaceId,
  });
  const space = data?.floors?.data?.[0];
  const { data: buildingData } = useTimezoneBuildingQuery({
    // skip validates this assertion.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { buildingId: buildingId! },
    skip: !buildingId,
  });

  const tz = useMemo(
    () => timezone || buildingData?.building?.site?.timezone || space?.timezone || LOCAL_TZ,
    [space?.timezone, timezone, buildingData],
  );

  return tz;
};
useTimezone.queries = {
  Space: gql`
    query TimezoneSpace($spaceId: String!) {
      floors(ids: [$spaceId]) {
        data {
          id: floor_id
          timezone
        }
      }
    }
  `,
  Buildings: gql`
    query TimezoneBuilding($buildingId: ID!) {
      building(id: $buildingId) {
        id
        name
        site {
          name
          timezone
        }
      }
    }
  `,
};
