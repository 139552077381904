import {
  useHeaderFloorsQuery,
  useHeaderRoomQuery,
  useHeaderZoneQuery,
  useHeaderBuildingsQuery,
} from '@/.generated/graphql';
import { PageLayoutHeader } from '@/components/PageLayout';
import { useSRZId } from '@/hooks';
import { getSingleGraphQLModel } from '@/utils/getSingleGraphQLModel';
import { gql } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { UserMenu } from './UserMenu';
import { useRef } from 'react';
import { Icon } from '@/components/primitives/icon/Icon';
import TimeDisplay from '@/components/TimeDisplay';
import { IconName } from '@/components/primitives/icon/generated/iconNames';
import { TagContainer } from '@/components/Tags/TagContainer';
import { useFeatureGate } from '@statsig/react-bindings';

interface HeaderProps {
  onDetailsPanelToggle: () => void;
}

export const Header: React.FC<HeaderProps> & { queries?: any } = ({ onDetailsPanelToggle }) => {
  const url = useLocation();
  const { roomId, spaceId, zoneId, buildingId } = useSRZId();
  const { data: spaceData, loading: spaceLoading } = useHeaderFloorsQuery({
    // skip validates this assertion.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { spaceId: spaceId! },
    skip: !spaceId,
  });
  const { data: buildingData } = useHeaderBuildingsQuery({
    // skip validates this assertion.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { buildingId: buildingId! },
    skip: !buildingId,
  });

  const space = getSingleGraphQLModel(spaceData?.floors);
  const skip = !spaceId || !roomId;
  const { data: roomData, loading: roomLoading } = useHeaderRoomQuery({
    // asserted by skip below
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { roomId: roomId! },
    skip,
  });
  const { data: zoneData, loading: zoneLoading } = useHeaderZoneQuery({
    // asserted by skip below
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { zoneId: zoneId! },
    skip: !zoneId,
  });

  let associatedTags =
    roomId || zoneId
      ? roomId
        ? roomData?.rooms?.data?.[0]?.tags
        : zoneData?.zones?.data?.[0]?.tags
      : spaceData?.floors?.data?.[0]?.tags;

  const room = roomData?.rooms?.data?.[0];
  const zone = zoneData?.zones?.data?.[0];
  const headerNameRef = useRef('');
  const iconNameRef = useRef<IconName>('layer');
  const { value: tagging } = useFeatureGate('tagging');

  if (!spaceLoading && !roomLoading && !zoneLoading) {
    const newHeaderName = buildingData?.building?.name
      ? buildingData?.building?.name
      : url.pathname === '/studio'
      ? space?.name
      : url.pathname === '/devices'
      ? 'All Devices'
      : roomId && room
      ? room.name
      : zoneId && zone
      ? zone.name
      : spaceId && space
      ? space.name
      : '';

    const newIconName =
      url.pathname === '/studio'
        ? 'layer'
        : roomId && room
        ? 'room'
        : zoneId && zone
        ? 'zone'
        : spaceId && space
        ? 'layer'
        : buildingId
        ? 'building'
        : 'layer';

    if (newHeaderName) {
      headerNameRef.current = newHeaderName;
    }
    if (newIconName) {
      iconNameRef.current = newIconName;
    }
  }

  const headerName = headerNameRef.current;
  const iconName = iconNameRef.current;
  const associateId = zoneId || roomId || spaceId;
  const isLoading = spaceLoading || roomLoading || zoneLoading;

  const queryToRefetch = [];
  if (zoneId) {
    queryToRefetch.push({
      query: Header.queries.Zone,
      variables: { zoneId },
    });
  } else if (roomId) {
    queryToRefetch.push({
      query: Header.queries.Room,
      variables: { roomId },
    });
  } else if (spaceId && (!roomId || !zoneId)) {
    queryToRefetch.push({
      query: Header.queries.Floors,
      variables: { spaceId },
    });
  }

  return (
    <PageLayoutHeader className="z-[9] bg-white">
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex flex-wrap items-center text-2xl font-semibold pt-6 justify-between w-full">
          {headerName && (
            <div className="flex items-center overflow-hidden md:mb-0 truncate">
              {' '}
              <div className="truncate flex flex-row" data-id="header-name-icon">
                <Icon name={iconName} className="mr-2 h-7 w-7 mt-[2px]"></Icon>
                {headerName}
              </div>
              {location.pathname !== '/devices' &&
                location.pathname !== '/studio' &&
                !isLoading &&
                tagging &&
                !buildingId && (
                  <div className="ml-4">
                    {' '}
                    <TagContainer
                      associatedTags={associatedTags?.map((tag) => tag.name) ?? []}
                      itemId={associateId}
                      queryToRefetch={queryToRefetch}
                    />
                  </div>
                )}
            </div>
          )}
          <div className="flex items-center gap-2 pt-0 md:mt-0">
            {' '}
            <div className="text-product-gray700 text-xs font-normal flex flex-row">
              Local Time: <TimeDisplay className="ml-2" />
            </div>
          </div>
        </div>
      </div>
      {!buildingId && (
        <button
          data-id="showHideDetails"
          type="button"
          className="mt-6 ml-2 md:mr-0 rounded flex cursor-pointer flex-row items-center gap-2 text-sm text-gray-600 border border-gray-300 hover:border-gray-400 active:border-gray-500"
          onClick={() => onDetailsPanelToggle()}
        >
          <Icon name="gear" size={16} className="m-1 flex-shrink-0" />
        </button>
      )}
    </PageLayoutHeader>
  );
};

Header.queries = {
  Buildings: gql`
    query HeaderBuildings($buildingId: ID!) {
      building(id: $buildingId) {
        id
        name
      }
    }
  `,
  Floors: gql`
    query HeaderFloors($spaceId: String!) {
      floors(ids: [$spaceId]) {
        data {
          id: floor_id
          name
          tags {
            id
            name
          }
          ...UserMenuFloor
        }
      }
    }
    ${UserMenu.fragments.Floor}
  `,
  Room: gql`
    query HeaderRoom($roomId: String!) {
      rooms(ids: [$roomId]) {
        data {
          id: room_id
          name
          tags {
            id
            name
          }
        }
      }
    }
  `,
  //can only get tags on zones, not rooms or spaces yet
  Zone: gql`
    query HeaderZone($zoneId: String!) {
      zones(ids: [$zoneId]) {
        data {
          id: zone_id
          name
          tags {
            id
            name
          }
        }
      }
    }
  `,
};
